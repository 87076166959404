import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
var GsidSsoService = /** @class */ (function () {
    function GsidSsoService(
    // private configurationService: ConfigurationService,
    loaderService, platformId) {
        this.loaderService = loaderService;
        this.platformId = platformId;
        if (isPlatformBrowser(platformId)) {
            // GSIDSSO.init(this.configurationService.getValue('ssoTokenUrl'));
        }
    }
    GsidSsoService.prototype.getGsId = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, payload;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAccessToken()];
                    case 1:
                        token = _a.sent();
                        if (!token) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, GSIDSSO.getAccessTokenPayload()];
                    case 2:
                        payload = _a.sent();
                        if (!payload) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, payload.GsId];
                }
            });
        });
    };
    GsidSsoService.prototype.getPayload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAccessToken()];
                    case 1:
                        token = _a.sent();
                        if (!token) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, GSIDSSO.getAccessTokenPayload()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GsidSsoService.prototype.getAccessToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loaderService.showLoader();
                        return [4 /*yield*/, GSIDSSO.getAccessToken()];
                    case 1:
                        token = _a.sent();
                        this.loaderService.hideLoader();
                        return [2 /*return*/, token];
                }
            });
        });
    };
    GsidSsoService.prototype.logout = function () {
        GSIDSSO.logout();
    };
    GsidSsoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GsidSsoService_Factory() { return new GsidSsoService(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: GsidSsoService, providedIn: "root" });
    return GsidSsoService;
}());
export { GsidSsoService };
