import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotEligibleComponent } from './site/not-eligible/not-eligible.component';
import { LoginOrSignupComponent } from './site/loginOrSignup/loginOrSignup.component';
import { ForgotPasswordComponent } from './site/forgotPassword/forgotPassword.component';
import { NewPasswordComponent } from './site/newPassword/newPassword.component';


const routes: Routes = [
  { path: '', component: LoginOrSignupComponent, pathMatch: 'full' },
  { path: 'not-eligible', component: NotEligibleComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'newpassword/:verificationCode', component: NewPasswordComponent },
  { path: 'newpassword', component: NewPasswordComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
