/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./avatar.component";
import * as i4 from "../../../services/user.service";
import * as i5 from "../../../services/gsid-sso.service";
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
export function View_AvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "avatar-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "avatar__no-photo"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "small": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "avatar__no-photo"; var currVal_1 = _ck(_v, 4, 0, !_co.big); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getFirstLetter(_co.firstName); _ck(_v, 5, 0, currVal_2); }); }
export function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "avatar", [], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 1163264, null, 0, i3.AvatarComponent, [i4.UserService, i5.GsidSsoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvatarComponentNgFactory = i1.ɵccf("avatar", i3.AvatarComponent, View_AvatarComponent_Host_0, { user: "user", hideContractOwner: "hideContractOwner", hideAdministrator: "hideAdministrator", big: "big" }, {}, []);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
