import { Directive, DoCheck, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { LoaderService } from '../services/loader.service';
@Directive({
    selector: '[loaderBtn]',
    host: {
        '(click)': 'onMouseClick($event)'
    }
})
export class LoaderDirective implements DoCheck {
    @Input() disabledBtn: any;
    @Output() loaderBtn: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private readonly el: ElementRef,
        private readonly loaderService: LoaderService
    ) { }

    ngDoCheck() {
        if (this.loaderService.getLoader() || this.disabledBtn === true) {
            this.el.nativeElement.classList.add('disabled');
        } else if (!this.loaderService.getLoader() && !this.disabledBtn) {
            this.el.nativeElement.classList.remove('disabled');
        }
    }

    onMouseClick(event: any) {
        if (this.loaderService.getLoader() || this.disabledBtn === true) {
            event.preventDefault()
            event.stopPropagation();
            return false;
        } else if (!this.loaderService.getLoader() && !this.disabledBtn) {
            this.loaderBtn.next(event);
        }
        return event;
    }
}
