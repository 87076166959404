import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { UserService } from '@services/user.service';

import { UserState } from '@enums/userState.enum';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: '[login]',
  templateUrl: 'login.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {
  @Input() hideFooter: any;
  @Input() isLoginOrSignup: any;
  public loginForm: FormGroup;
  public isSubmitted: boolean = false;
  public loginInProgress = false;
  public errorMsg: any;
  public facebookLoginUrl: any;
  public googleLoginUrl: any;
  public showSocialLoginButtons: boolean;
  public showAnotherContractOwnerErrorMsg = false;
  public showRegisterNewUserErrorMsg = false;
  public showContactPartnerErrorMsg = false;

  public loginDto: any;
  public emailAddress: any;
  public contractOwner: any;

  public webShopUrl: string;

  constructor(
    fb: FormBuilder,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly configurationService: ConfigurationService
  ) {
    const config = this.configurationService.getConfig();
    this.webShopUrl = config.AccountsUi;

    this.loginForm = fb.group({
      'emailAddress': [null, [Validators.required]],
      'password': [null, Validators.required],
    });
  }

  loginBtnClicked() {
    if (this.loginInProgress) return;
    this.loginInProgress = true;
    this.errorMsg = false;
    this.loginDto = null;
    this.contractOwner = null;
    this.showAnotherContractOwnerErrorMsg = false;
    this.showRegisterNewUserErrorMsg = false;
    this.showContactPartnerErrorMsg = false;

    this.userService.login(this.loginForm.controls['emailAddress'].value, this.loginForm.controls['password'].value).then((response) => {
      this.loginDto = response;
      this.loginInProgress = false;

      switch (response.UserState) {
        case UserState.ConnectedCompanyHasAnotherContractOwner:
          if (this.loginDto.Company.CompanyUsers) {
            this.contractOwner = this.loginDto.Company.CompanyUsers.find((user: any) => {
              if (user.Roles && user.Roles.length !== 0) {
                return user.Roles.find((role: any) => {
                  return role.RoleName === "contract-owner";
                });
              }
              return false;
            });
            if (!this.contractOwner) {
              this.contractOwner = this.loginDto.Company.Owner;
            }
          }

          this.showAnotherContractOwnerErrorMsg = true;

          break;
        case UserState.ConnectedCompanyNotContainsExistingWebshopPartners:
          this.emailAddress = this.loginForm.controls['emailAddress'].value;
          this.showRegisterNewUserErrorMsg = true;
          break;
        case UserState.ContactUKOfficeForNewInvitation:
          this.showContactPartnerErrorMsg = true;
          break;
      }
    }, error => {
      if (error.status === 400) {
        if (error.error.UserState) {
          this.errorMsg = error.error.ErrorMessage;
        } else {
          this.errorMsg = error.error;

        }

        if (this.errorMsg !== '""' && this.errorMsg !== '' && typeof this.errorMsg === 'string') {
          this.errorMsg = 'USER_LOGIN_ERROR_MESSAGE_' + this.errorMsg;
        } else {
          this.errorMsg = 'USER_LOGIN_ERROR_MESSAGE_IDENTITY_ERROR';
        }
      }
      this.loginInProgress = false;
    });
  }

  onKeypressed(event: any) {
    if (event.keyCode === 13) {
      this.loginBtnClicked();
    }
  }
}
