import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoaderService } from '../../services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileModel } from './profile.model';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '@services/configuration.service';
import { UserState } from '@enums/userState.enum';

@Component({
  selector: '[signup]',
  templateUrl: 'signup.html',
  styleUrls: ['./signup.component.less'],
})
export class SignupComponent implements OnInit {
  @Input() hideFooter: any;
  @Input() autoLogin: any;
  //public user: SignupModel = new SignupModel();
  public signupForm: FormGroup;
  public signupFormSubmitted: boolean = false;
  public signupFinished: boolean = false;
  public notificationHeader: any = 'USER_SIGN_UP_SUCCESS_NOTIFICATION_HEADER';
  public notificationMessage: any = 'USER_SIGN_UP_SUCCESS_NOTIFICATION_MESSAGE';
  public notificationLinkText: any = 'USER_SIGN_UP_SUCCESS_NOTIFICATION_LOGIN_BUTTON';
  public notificationLinkAddress: any = '/login';
  public notificationClass: any = 'dark';
  public errorMsg: any;
  public isContactPrefInfoHidden: boolean = false;
  public isPrivacyPolicyInfoHidden: boolean = false;
  public signUpUrl: string;
  public showContactPartnerErrorMsg = false;

  constructor(
    fb: FormBuilder,
    public loaderService: LoaderService,
    route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private profileModel: ProfileModel,
    private readonly userService: UserService,
    private readonly configurationService: ConfigurationService
  ) {

    this.signupForm = fb.group(this.profileModel.getForm(), { validator: this.profileModel.validateReenterEmail() });

  }

  ngOnInit() {
    const config = this.configurationService.getConfig();
    this.signUpUrl = config.AccountsUi + "shop/signup";
  }

  setPassword(e: any) {
    this.signupForm.controls['password'].setValue(e);
  }

  setCountry(e: any) {
    this.signupForm.controls['countryId'].setValue(e.CountryId);
  }

  async signupBtnClicked(e: any) {
    this.showContactPartnerErrorMsg = false;
    try {
      //TODO: event-be jön a token, amit majd fel kell küldeni
      if (this.loaderService.getLoader()) return;

      this.signupFormSubmitted = true;

      if (this.signupForm.valid) {
        const response = await this.userService.signup(this.signupForm.value);

        if (response.UserState === UserState.ContactUKOfficeForNewInvitation) {
          this.showContactPartnerErrorMsg = true;
        }
      }
    } catch (ex) {
      // await this.modalService.notify({
      //   header: this.translateService.instant('SIGNUP_GENERIC_ERROR_POPUP_HEADER'),
      //   message: this.translateService.instant('SIGNUP_GENERIC_ERROR_POPUP_MESSAGE'),
      //   okLabel: this.translateService.instant('SIGNUP_GENERIC_ERROR_POPUP_CLOSE_BUTTON')
      // });
    }
  }

  logInNowBtnClicked() {
    // if (this.loaderService.getLoader()) return;
    // this.userService.login(this.signupForm.controls['email'].value, this.signupForm.controls['password'].value).then((response) => {

    // }, (error: any) => {
    //   console.log(error);
    // });
  }

  onPaste(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  onContactPrefInfoClicked(): void {
    this.isContactPrefInfoHidden = !this.isContactPrefInfoHidden;
  }

  onPrivacyPolicyInfoClicked(): void {
    this.isPrivacyPolicyInfoHidden = !this.isPrivacyPolicyInfoHidden;
  }


}
