import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var LanguageService = /** @class */ (function () {
    function LanguageService(translateService) {
        this.translateService = translateService;
        this.languages = [
            'de-de',
            'en-ca',
            'en-gb',
            'en-ie',
            'en-us',
            'es-es',
            'it-it',
            'it-sm',
            'ja-jp'
        ];
        this.translateService.setDefaultLang('en-us');
        this.translateService.use('en-us');
    }
    LanguageService.prototype.setLanguage = function (isoCode) {
        if (!isoCode) {
            isoCode = 'en-us';
        }
        if (isoCode.length === 2) {
            isoCode = isoCode + '-' + isoCode;
        }
        isoCode = isoCode.toLowerCase();
        if (this.languages.indexOf(isoCode) === -1) {
            var languageCode = isoCode.split('-');
            languageCode = languageCode[0] + '-' + languageCode[0];
            if (this.languages.indexOf(languageCode) === -1) {
                isoCode = 'en-us';
            }
            else {
                isoCode = languageCode;
            }
        }
        this.translateService.use(isoCode);
    };
    LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.TranslateService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
