import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { EmailFormatValidator } from '../../validators/emailFormat.validator';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  templateUrl: 'forgotPassword.html',
})
export class ForgotPasswordComponent {
  public emailAddress: string = '';
  public forgotPasswordForm:any;
  public submitted:boolean = false;

  public resetInProgress = false;
  public showErrorMsg = false;
  public showSuccessMsg = false;
  public notificationHeader: any = 'USER_FORGOT_SUCCESS_NOTIFICATION_HEADER';
  public notificationMessage: any = 'USER_FORGOT_SUCCESS_NOTIFICATION_MESSAGE';
  public notificationLinkText: any = 'USER_FORGOT_SUCCESS_NOTIFICATION_LOGIN_BUTTON';
  public notificationHint: any = 'USER_FORGOT_SUCCESS_NOTIFICATION_HINT';
  public notificationLinkAddress: any = '/login';
  public notificationClass: any = 'dark';

  constructor(private userService: UserService, private router: Router,  fb: FormBuilder) {
    this.forgotPasswordForm = fb.group({
      'email': [null, [Validators.required, EmailFormatValidator, Validators.maxLength(80)]]
    });

  }

  public resetBtnClicked() {
    this.submitted = true;
    if (this.resetInProgress) return;    
    if(this.forgotPasswordForm.invalid) return;

    this.resetInProgress = true;
    this.showErrorMsg = false;
    this.showSuccessMsg = false;

    this.userService.forgotPassword(this.forgotPasswordForm.controls['email'].value).then((response: any) => {
      
      this.resetInProgress = false;
      if(response.EmailSent){
        this.showSuccessMsg = true;
      }
    }, (error: any) => {
      if (error.status === 400) {
        this.showErrorMsg = true;
      }
      this.resetInProgress = false;
    });
  }

  logInNowBtnClicked() {
    this.router.navigate(['/login']);
  }

  onBackBtnClicked(){
    this.router.navigate(['/login']);
  }


}
