import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loader: any;
    constructor() {
        this.loader = 0;
    }

    getLoader() {
        return this.loader > 0;
    }

    showLoader() {
        this.loader++;
    }

    hideLoader() {
        if (this.loader !== 0)
            this.loader--;
    }

}
