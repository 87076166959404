/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./login.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "../../services/user.service";
import * as i7 from "../../services/configuration.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "login-form-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "btn btn-primary btn-block"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "icon-right-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("USER_LOGIN_SECTION_HEADER_SIGN_IN")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.webShopUrl, ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("USER_LOGIN_LOG_IN_BUTTON")); _ck(_v, 6, 0, currVal_2); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["login", ""]], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoginComponent, [i4.FormBuilder, i5.Router, i6.UserService, i7.ConfigurationService], null, null)], null, null); }
var LoginComponentNgFactory = i1.ɵccf("[login]", i3.LoginComponent, View_LoginComponent_Host_0, { hideFooter: "hideFooter", isLoginOrSignup: "isLoginOrSignup" }, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
