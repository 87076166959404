import { Component, Input, AfterContentInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { GsidSsoService } from '../../../services/gsid-sso.service';
@Component({
    selector: "avatar",
    templateUrl: 'avatar.html',
    styleUrls: ['./avatar.component.less']
})
export class AvatarComponent implements AfterContentInit {
    @Input() user: any;
    @Input() hideContractOwner: any;
    @Input() hideAdministrator: any;
    @Input() big: any;

    public isAdministrator = false;
    public isContractOwner = false;
    public currentUser: any;
    public firstName: string;
    constructor(
        private readonly userService: UserService,
        private readonly gsidSsoService: GsidSsoService
    ) { }

    async ngOnInit() {

        // const payload = await this.gsidSsoService.getPayload();
        // this.firstName = payload.FirstName;

    }

    ngAfterContentInit() {
        if (this.currentUser && this.currentUser.Company && this.currentUser.Company.Owner.GsId === this.user.GsId && !this.hideAdministrator) {
            this.isAdministrator = true;
        }
        this.hasContractOwnerRole();


    }

    hasContractOwnerRole() {
        return;
    }

    getFirstLetter(name: string) {
        if (!name) return '?';
        return name[0];
    }
}
