import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import { ConfigurationService } from './configuration.service';
import { LoaderService } from './loader.service';

/*Glogális js változók*/
declare var GSIDSSO: any;

@Injectable({
  providedIn: 'root'
})
export class GsidSsoService {

  constructor(
    // private configurationService: ConfigurationService,
    private readonly loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId
  ) {

    if (isPlatformBrowser(platformId)) {
      // GSIDSSO.init(this.configurationService.getValue('ssoTokenUrl'));
    }
  }

  async getGsId() {
    const token = await this.getAccessToken();

    if (!token) {
      return null;
    }

    const payload = await GSIDSSO.getAccessTokenPayload();
    if (!payload) {
      return null;
    }
    return payload.GsId;

  }

  async getPayload() {
    const token = await this.getAccessToken();

    if (!token) {
      return null;
    }

    return await GSIDSSO.getAccessTokenPayload();    
  }

  async getAccessToken() {
    this.loaderService.showLoader();
    const token = await GSIDSSO.getAccessToken()
    this.loaderService.hideLoader();
    return token;
  }

  logout() {
    GSIDSSO.logout();
  }
}
