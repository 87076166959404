import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { ConfigurationService } from './configuration.service';
import { GsidSsoService } from './gsid-sso.service';
import { AccountPage } from '@enums/accountPage.enum';
import { UserState } from '@enums/userState.enum';

/*Glogális js változók*/
declare var GSIDSSO: any;
declare var config: any;

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiUrl: string;
    private webshopUrl: string;
    private federatedLogins: any;
    public redirectUrl: any;

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private readonly gsidSsoService: GsidSsoService,
        private readonly configurationService: ConfigurationService
    ) {
        const config = this.configurationService.getConfig();
        this.webshopUrl = config.AccountsUi;
        this.apiUrl = config.GsIdApi;
    }

    login(emailAddress: string, password: string) {
        var request = {
            'Email': emailAddress,
            'Password': password,
            'Token': null
        };

        this.loaderService.showLoader();

        return this.http.post((this.apiUrl + 'api/UserV2/LoginFromBCPromotionSite'), request)
            .toPromise()
            .then((response: any) => {
                this.loaderService.hideLoader();
                return this.redirectByUserState(response);
            }, (error: any) => {
                this.loaderService.hideLoader();
                throw error;
            });
    }

    forgotPassword(emailAddress: string) {
        var request = {
            'EmailAddress': emailAddress
        };
        this.loaderService.showLoader();
        return this.http.post(`${this.apiUrl}api/UserV2/ResetUserPassword`, request)
            .toPromise()
            .then((response: any) => {
                this.loaderService.hideLoader();
                return response;
            }, (error: any) => {
                this.loaderService.hideLoader();
                throw error;
            });
    }

    async userLoggedIn() {
        return Promise.resolve(!!await this.gsidSsoService.getAccessToken());
    }

    logout(withoutRedirect?: any) {
        this.gsidSsoService.logout();
        if (!withoutRedirect)
            this.router.navigate(['/login']);
    }

    signup(user: any): any {
        this.loaderService.showLoader();

        return this.http.post(`${this.apiUrl}api/UserV2/CreateAndVerifyUser`, user)
            .toPromise()
            .then((response: any) => {

                if (response.UserState !== UserState.ContactUKOfficeForNewInvitation) {
                    this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                } else {
                    this.loaderService.hideLoader();
                    return response;
                }

            }, (error: any) => {
                this.loaderService.hideLoader();
                return {
                    ErrorMessage: error,
                    Success: false
                }
            });

    }

    checkEmail(email: string) {
        var request = {
            'EmailAddress': email,
        };
        return this.http.put((this.apiUrl + 'api/UserV2/CheckEmail'), request)
            .toPromise()
            .then(response => {
                return response;
            });
    }

    setNewPasswordToUser(newPasswordForm: any) {
        const request = {
            verificationCode: newPasswordForm.verificationCode,
            password: newPasswordForm.newPassword
        }
        this.loaderService.showLoader();

        return this.http.put((this.apiUrl + 'api/UserV2/SetNewPasswordToUser'), request)
            .toPromise()
            .then(response => {
                this.loaderService.hideLoader();
                return response;
            }, (error: any) => {
                this.loaderService.hideLoader();
                throw error;
            });
    }

    isVerificationCodeExists(code: any) {
        const request = {
            verificationCode: code,
        };
        this.loaderService.showLoader();

        return this.http.put((this.apiUrl + 'api/UserV2/IsVerificationCodeExists'), request)
            .toPromise()
            .then(response => {
                this.loaderService.hideLoader();
                return response;
            });
    }

    redirectByUserState(response) {
        switch (response.UserState) {
            case UserState.UserAuthenticated:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break;
            case UserState.IdentityError:
                this.unhandledState(response.UserState);
                break;

            case UserState.UserAlreadyHasRightForBCAndAlreadyPurchasedTenant:
                this.redirectToWebshop(AccountPage.Subscriptions, response.AccessToken, response.TokenId);
                break; // Go to Subscriptions/Subscriptions page to purchase further BC SaaS licenses
            case UserState.UserAlreadyHasRightForBC:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break; // Go to Order/Pricing to purchase BC SaaS licenses
            case UserState.UserInvitationUpdatedWithBC:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break; // User already has invitation but not for BC. Token updated
            case UserState.UserInvitationUpdatedWithBCNotSuccess:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break; // User already has invitation but not for BC. Token updated

            case UserState.ConnectedCompanyHasAnotherContractOwner:
            case UserState.ConnectedCompanyNotContainsExistingWebshopPartners:
            case UserState.ContactUKOfficeForNewInvitation:
                return response;
                break;


        }
    }

    unhandledState(userState: UserState) {
        alert(`Unhandled user state: ${UserState[userState]}`);
    }

    redirectToWebshop(page: AccountPage, accessToken: any, sftoken?: any) {
        switch (page) {
            case AccountPage.InitialPurchase:
                window.location.href = `${this.webshopUrl}order/pricing?sftoken=${sftoken}&lang=en&accessToken=${accessToken}`;
                break;
            case AccountPage.AdditionalPurchase:
                window.location.href = `${this.webshopUrl}additional/pricing?sftoken=${sftoken}&lang=en&accessToken=${accessToken}`;
                break;
            case AccountPage.Subscriptions:
                window.location.href = `${this.webshopUrl}subscriptions/subscriptions?sftoken=${sftoken}&lang=en&accessToken=${accessToken}`;
                break;
        }

    }
}
