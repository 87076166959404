import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-nav-menu',
    templateUrl: 'nav-menu.component.html',
    styleUrls: ['./nav-menu.component.less'],
    host: {
        '(document:click)': 'onClick($event)',
    }
})
export class NavMenuComponent implements OnInit {
    public showProductsDropdown: boolean;
    public showProfileDropdown: boolean;
    public userLoggedIn: any;
    @Input() hideNavigation: boolean;

    constructor(
        private readonly userService: UserService,
        private readonly el: ElementRef,
        private readonly router: Router,
    ) { }

    ngOnInit() {
        this.showProductsDropdown = false;
        this.showProfileDropdown = false;
        // this.profileService.profileSubject.subscribe((data: any) => this.user = data);

        // this.router.events.pipe(
        //     filter(e => e instanceof NavigationEnd)
        // ).subscribe((e: NavigationEnd) => {
        //     this.checkUserLoggedIn();
        // });
        // this.checkUserLoggedIn();
    }

    checkUserLoggedIn() {
        this.userService.userLoggedIn().then(response => {
            this.userLoggedIn = response;
        });
    }

    showProfileBtnClicked() {
        this.showProductsDropdown = false;
        this.showProfileDropdown = !this.showProfileDropdown;
    }

    showProductsBtnClicked() {
        this.showProductsDropdown = !this.showProductsDropdown;
        this.showProfileDropdown = false;
    }

    public logout() {
        this.showProfileDropdown = false;
        this.userService.logout();
    }

    public onClick(e: any) {
        const profileDropdownContainer = this.el.nativeElement.querySelector('.profile-dropdown');
        const productsDropdownContainer = this.el.nativeElement.querySelector('.products-dropdown');

        if (this.showProfileDropdown !== false && profileDropdownContainer) {
            if (!profileDropdownContainer.contains(e.target)) {
                this.showProfileDropdown = false;
            } else {
                if (profileDropdownContainer.querySelector('.dropdown-menu').contains(e.target)) {
                    this.showProfileDropdown = false;
                }
            }
        }

        if (this.showProductsDropdown !== false && productsDropdownContainer) {
            if (!productsDropdownContainer.contains(e.target)) {
                this.showProductsDropdown = false;
            } else {
                if (productsDropdownContainer.querySelector('.dropdown-menu').contains(e.target)) {
                    this.showProductsDropdown = false;
                }
            }
        }
    }


}
