import { Injectable, Injector } from '@angular/core';
import { TranslateService, DefaultLangChangeEvent, LangChangeEvent } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private loader: any;
    private languages = [
        'de-de',
        'en-ca',
        'en-gb',
        'en-ie',
        'en-us',
        'es-es',
        'it-it',
        'it-sm',
        'ja-jp'
    ];
    constructor(
        private translateService: TranslateService
        ) {
        this.translateService.setDefaultLang('en-us');
        this.translateService.use('en-us');
    }

    setLanguage(isoCode?: any) {
        if (!isoCode) {
            isoCode = 'en-us';
        }

        if (isoCode.length === 2) {
            isoCode = isoCode + '-' + isoCode;
        }

        isoCode = isoCode.toLowerCase();

        if (this.languages.indexOf(isoCode) === -1) {
            let languageCode = isoCode.split('-');
            languageCode = languageCode[0] + '-' + languageCode[0];
            if (this.languages.indexOf(languageCode) === -1) {
                isoCode = 'en-us';
            } else {
                isoCode = languageCode;
            }
        }
        this.translateService.use(isoCode);
    }

}
