import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LengthLimitDirective } from './lengthLimit.directive'
import { SvgIconDirective } from './svgIcon.directive'
import { LoaderDirective } from './loader.directive'
import { OverflowDirective } from './overflow.directive'
import { EnscrollDirective } from './enscroll.directive';
import { EnterBtnDirective } from './enterBtn.directive';
import { EscBtnDirective } from './escBtn.directive';
import { LabelInsideDirective } from './labelInside.directive';

@NgModule({
    imports: [
        BrowserModule
    ],
    declarations: [
        LengthLimitDirective,
        SvgIconDirective,
        LoaderDirective,
        OverflowDirective,
        EnscrollDirective,
        EnterBtnDirective,
        EscBtnDirective,
        LabelInsideDirective
    ],
    exports: [
        LengthLimitDirective,
        SvgIconDirective,
        LoaderDirective,
        OverflowDirective,
        EnscrollDirective,
        EnterBtnDirective,
        EscBtnDirective,
        LabelInsideDirective
    ]
})
export class DirectivesModule { }
