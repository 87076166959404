<div class="inline-email-notification" [ngClass]="notificationClass">
    <div class="icon-inline-email-notification" *ngIf="!hideNotificationIcon"></div>
    <div class="inline-email-notification__header" *ngIf="notificationHeader">
        {{notificationHeader | translate }}
    </div>
    <div class="inline-email-notification__message" *ngIf="notificationMessage">
        {{notificationMessage | translate }}
    </div>
    
    <div class="inline-email-notification__link" *ngIf="notificationLinkAddress && notificationLinkText">        
        <a [routerLink]="[notificationLinkAddress]">{{notificationLinkText | translate }}</a>
    </div>

    <div class="inline-email-notification__link" *ngIf="notificationLinkFunction && notificationLinkText">        
        <a (click)="notificationLinkClicked()">{{notificationLinkText | translate }}</a>
    </div>
    
    <div class="inline-email-notification__hint" *ngIf="notificationHint">
        {{notificationHint | translate }}
    </div>
</div>