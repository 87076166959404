import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './site/signup/signup.component';
import { LoginComponent } from './site/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { SvgIconDirective } from './components/svg-icons/svg-icon.directive';
import { Tooltip } from './components/tooltip/tooltip.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LanguageLoader } from './services/languageLoader.service';

import { FormGroupInputComponent } from './components/formGroupInput/formGroupInput.component';
import { PasswordInputComponent } from './components/passwordInput/passwordInput.component';
import { UpperCaseTranslatePipe } from './pipes/upperCaseTranslate.pipe';
import { AvatarComponent } from './components/nav-menu/avatar/avatar.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NotEligibleComponent } from './site/not-eligible/not-eligible.component';
import { LoginOrSignupComponent } from './site/loginOrSignup/loginOrSignup.component';
import { CountryFilter } from './components/countrySelect/countryFilter.filter';
import { CountrySelectComponent } from './components/countrySelect/countrySelect.component';
import { DirectivesModule } from './directives/directives.module';
import { TooltipContent } from './components/tooltip/tooltipContent.component';
import { ConfigurationService } from './services/configuration.service';
import { ForgotPasswordComponent } from './site/forgotPassword/forgotPassword.component';
import { NewPasswordComponent } from './site/newPassword/newPassword.component';
import { InlineEmailNotificationComponent } from './components/inlineEmailNotification/inlineEmailNotification.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new LanguageLoader(http, 'assets/i18n/', '.json');
}

export function loadJsonsFactory(
  configurationService: ConfigurationService
): any {
  return () => {
    return Promise.all([
      configurationService.initialize()
    ]).then(() => {
      return true;
    });
  };
}

export function languageInitializer(
  translateLoader: TranslateLoader) {
  return () => new Promise(resolve => {
    translateLoader.getTranslation('en-us').toPromise().then(() => {
      resolve(true);
    });
    resolve(true);
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    FooterComponent,
    LoaderComponent,
    NavMenuComponent,
    SvgIconDirective,
    Tooltip,
    TooltipContent,
    FormGroupInputComponent,
    PasswordInputComponent,
    UpperCaseTranslatePipe,
    AvatarComponent,
    NotEligibleComponent,
    LoginOrSignupComponent,
    CountryFilter,
    CountrySelectComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    InlineEmailNotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: languageInitializer,
      deps: [TranslateLoader],
      multi: true
    },
    ConfigurationService,
    { provide: APP_INITIALIZER, useFactory: loadJsonsFactory, deps: [ConfigurationService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
