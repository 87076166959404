import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({ selector: '.label-inside' })
export class LabelInsideDirective implements AfterViewInit {
    private label: any;
    private input: any;
    constructor(
        private readonly el: ElementRef
    ) { }

    ngAfterViewInit() {
        this.input = this.el.nativeElement.querySelector('input');
        this.label = this.el.nativeElement.querySelector('label');
        if (this.input && this.label) {
            this.label.addEventListener('click', () => {
                this.labelClickHandler();
            });
        }
    }

    private labelClickHandler() {
        this.input.focus();
    }
}
