/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/nav-menu/nav-menu.component.ngfactory";
import * as i3 from "./components/nav-menu/nav-menu.component";
import * as i4 from "./services/user.service";
import * as i5 from "@angular/router";
import * as i6 from "./components/footer/footer.component.ngfactory";
import * as i7 from "./components/footer/footer.component";
import * as i8 from "./components/loader/loader.component.ngfactory";
import * as i9 from "./components/loader/loader.component";
import * as i10 from "./services/loader.service";
import * as i11 from "./app.component";
import * as i12 from "./services/language.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content background-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-nav-menu", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavMenuComponent_0, i2.RenderType_NavMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.NavMenuComponent, [i4.UserService, i1.ElementRef, i5.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "footer", [["app-footer", ""]], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(7, 49152, null, 0, i7.FooterComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["loader", ""]], null, null, null, i8.View_LoaderComponent_0, i8.RenderType_LoaderComponent)), i1.ɵdid(9, 49152, null, 0, i9.LoaderComponent, [i10.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i11.AppComponent, [i12.LanguageService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
