export enum UserState {
  NotAuthenticated,
  UserAuthenticated,
  UserAuthProblemWithErrorMessage,
  IdentityError,

  UserAlreadyHasRightForBCAndAlreadyPurchasedTenant, // Go to Subscriptions/Subscriptions page to purchase further BC SaaS licenses
  UserAlreadyHasRightForBC, // Go to Order/Pricing to purchase BC SaaS licenses
  UserInvitationUpdatedWithBC, // User already has invitation but not for BC. Token updated
  UserInvitationUpdatedWithBCNotSuccess, // User already has invitation but not for BC. Token updated

  ConnectedCompanyHasAnotherContractOwner, // Error => Please contact with your conctract owner
  ConnectedCompanyNotContainsExistingWebshopPartners, // Error = > Please create new GSID
  ContactUKOfficeForNewInvitation, // Error = > Please contact with the UK office for invitation

  UserCountryNotSupported, // Can't register, user's country not supported, embargoed
  UserCountryNeedsReconfirmation, // The user must reconfirm his/her country
  UserNeedsReRegistration, // For some reason the user's partner is not HongKong
  UserBelongsToAmericaMarket // For US customers, who must use the closed webhop
}
