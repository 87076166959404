import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: '[inline-email-notification]',
    templateUrl: 'inlineEmailNotification.html'
})
export class InlineEmailNotificationComponent {
    @Input() notificationHeader: any;
    @Input() notificationMessage: any;
    @Input() notificationLinkAddress: any;
    @Input() notificationLinkText: any;
    @Input() notificationHint: any;
    @Input() notificationClass: any;
    @Input() hideNotificationIcon:any;
    
    @Output() notificationLinkFunction: EventEmitter<any> = new EventEmitter<any>();

    constructor() {

    }

    notificationLinkClicked() {
        this.notificationLinkFunction.emit(true);
    }

}
