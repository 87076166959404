import { Directive, EventEmitter, Output, HostListener } from '@angular/core';
import { LoaderService } from '../services/loader.service';
@Directive({
    selector: '[escBtn]'
})
export class EscBtnDirective {

    @Output() escBtn: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private readonly loaderService: LoaderService
    ) { }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.loaderService.getLoader()) { return; }

        if (event.keyCode === 27) {
            event.preventDefault();
            event.stopPropagation();
            this.escBtn.next(event);
        }
    }
}
