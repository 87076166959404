<input type="password" [(ngModel)]="modelValue" name="password" #password="ngModel" (ngModelChange)="checkPasswordStrengthLevel()" class="password-with-strength"  [ngClass]="{'invalid': ((!password.pristine || submitted)&& (passwordStrengthLevel < 5 || !checkPasswordStrengthHasSpecialCharacter())) }"  on-blur="inputOnBlur()" on-focus="inputOnFocus()"/>

<label [ngClass]="{'label--empty': !modelValue}">{{'PASSWORD_INPUT_PASSWORD_LABEL' | translate}}</label>
<div class="password-strength">
    <div class="line" [ngClass]="{'active': passwordStrengthLevel >= 1}"></div>
    <div class="line" [ngClass]="{'active': passwordStrengthLevel >= 2}"></div>
    <div class="line" [ngClass]="{'active': passwordStrengthLevel >= 3}"></div>
    <div class="line" [ngClass]="{'active': passwordStrengthLevel >= 4}"></div>
    <div class="line" [ngClass]="{'active': passwordStrengthLevel >= 5}"></div>
</div>
<div class="active dropdown-menu dropdown-notification dropdown-right password-strength__popup" *ngIf="showError && (!password.pristine && (passwordStrengthLevel < 5 || !checkPasswordStrengthHasSpecialCharacter()))">
    <div class="dropdown-notification__header">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_HEADER' | translate}}</div>
    <div class="dropdown-notification__body">
        <div>
            <div class="header__do">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_DO_HEADER' |translate}}</div>
            <ul>
                <li [ngClass]="{'removed': checkPasswordStrengthLength()}">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_6_CHARACTERS_MESSAGE' | translate}}</li>
                <li [ngClass]="{'removed': checkPasswordStrengthHasLetter()}">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_ONE_LETTER_MESSAGE' | translate}}</li>
                <li [ngClass]="{'removed': checkPasswordStrengthHasCapital()}">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_ONE_CAPITAL_MESSAGE' | translate}}</li>
                <li [ngClass]="{'removed': checkPasswordStrengthHasNumber()}">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_ONE_NUMBER_MESSAGE' | translate}}</li>
            </ul>
            <div class="header__dont">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_DONT_HEADER' | translate}}</div>
            <ul>
                <li [ngClass]="{'removed': checkPasswordStrengthNotEqualEmail()}">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_NOT_EMAIL_ADDRESS_MESSAGE' | translate}}</li>
                <li [ngClass]="{'removed': checkPasswordStrengthHasSpecialCharacter()}">{{'PASSWORD_INPUT_PASSWORD_POLICY_POPUP_SPEC_CHARACTERS_MESSAGE' | translate}}</li>
            </ul>
        </div>
    </div>
</div>