<section class="vertical-center horizontal-center">
    <div class="login-form-wrapper forgot-password" *ngIf="!showSuccessMsg">
        <h1>{{'USER_NEW_PASSWORD_SECTION_HEADER' | translate }}</h1>

        <div class="fix-content-wrapper">

            <div class="form-group label-inside" [ngClass]="{'invalid': showErrorMsg || submitted && !newPasswordForm.verificationCode || invalidVerificationCode }">
                <input type="text" class="form-control" id="current-password" name="current-password" [(ngModel)]="newPasswordForm.verificationCode" on-blur="inputOnBlur()" on-focus="inputOnFocus()"
                 (change)="verificationCodeOnChange()" (ngModelChange)="onVerificationCodeChanged($event)" />
                <label for="current-password" [ngClass]="{'label--empty': !newPasswordForm.verificationCode}">{{'USER_NEW_PASSWORD_VERIFICATION_CODE_LABEL' | translate}}</label>
        
                <div class="active dropdown-menu dropdown-notification dropdown-right password-strength__popup" *ngIf="showInvalidVerificationCode && invalidVerificationCode">                
                    <div class="dropdown-notification__header">{{'USER_NEW_PASSWORD_VERIFICATION_CODE_ERROR_HEADER' | translate}}</div>
                    <div class="dropdown-notification__body"><br />
                        <div>
                            <b>{{'USER_NEW_PASSWORD_VERIFICATION_CODE_ERROR_MESSAGE' |translate}}</b>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="form-group label-inside" password-input [(model)]="newPasswordForm.newPassword" [submitted]="submitted">
            </div>
        
            <div class="form-group label-inside" [ngClass]="{'valid': checkReenterPassword() === true, 'invalid': checkReenterPassword() === false }">        
                <input type="password" class="form-control" id="reenter-password" name="reenter-password" [(ngModel)]="newPasswordForm.reenterPassword"
                />
                <label for="reenter-password" [ngClass]="{'label--empty': !newPasswordForm.reenterPassword}">{{'USER_NEW_PASSWORD_REENTER_PASSWORD_LABEL' | translate}}</label>
            </div>
        
            <div >
                <a class="btn btn-primary btn-block"  (loaderBtn)="saveNewPassword()" (enterBtn)="saveNewPassword()">{{'USER_NEW_PASSWORD_SAVE_BUTTON' | translate}}</a>                
            </div>
        </div>
    </div>
    

    <div *ngIf="showSuccessMsg" inline-email-notification [notificationHeader]="notificationHeader" [notificationMessage]="notificationMessage"
    (notificationLinkFunction)="logInNowBtnClicked()" [notificationLinkText]="notificationLinkText" [notificationHint]="notificationHint"
     [notificationClass]="notificationClass" [hideNotificationIcon]="true"></div>

</section>
