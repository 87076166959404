import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.loader = 0;
    }
    LoaderService.prototype.getLoader = function () {
        return this.loader > 0;
    };
    LoaderService.prototype.showLoader = function () {
        this.loader++;
    };
    LoaderService.prototype.hideLoader = function () {
        if (this.loader !== 0)
            this.loader--;
    };
    LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
