import { FormGroup, FormBuilder, Validators, FormControl, AsyncValidatorFn } from '@angular/forms';
import { BlankValidator } from '../../validators/blank.validator';
import { EmailFormatValidator } from '../../validators/emailFormat.validator';
import * as i0 from "@angular/core";
import * as i1 from "../../services/user.service";
var ProfileModel = /** @class */ (function () {
    function ProfileModel(userService) {
        this.userService = userService;
        var fb = new FormBuilder();
        this.firstName = fb.control(null, [Validators.required, Validators.maxLength(30), Validators.pattern(/^[^!@#$%^&*()_+\=\[\]{};:"\\|,<>\/?]*$/), BlankValidator]);
        this.lastName = fb.control(null, [Validators.required, Validators.maxLength(30), Validators.pattern(/^[^!@#$%^&*()_+\=\[\]{};:"\\|,<>\/?]*$/), BlankValidator]);
        this.email = fb.control(null, [Validators.required, EmailFormatValidator, Validators.maxLength(80)], [this.asyncCheckEmail(this.userService)]);
        this.reenterEmail = fb.control(null, Validators.compose([Validators.required]));
        this.password = fb.control(null, [Validators.required, Validators.maxLength(50), this.validateReenterPassword]);
        this.reenterPassword = fb.control(null, Validators.compose([Validators.required, this.validateReenterPassword]));
        this.countryId = fb.control(null, Validators.required);
        this.companyName = fb.control(null, [Validators.maxLength(100)]);
        this.contactable = fb.control(null, []);
        this.CompanyGsId = fb.control(null, []);
        this.isPrivacyPolicyAccepted = fb.control(null, [Validators.required]);
    }
    ProfileModel.prototype.getForm = function () {
        this.firstName.setValue(null);
        this.lastName.setValue(null);
        this.email.setValue(null);
        this.reenterEmail.setValue(null);
        this.password.setValue(null);
        this.reenterPassword.setValue(null);
        this.countryId.setValue(null);
        this.companyName.setValue(null);
        this.contactable.setValue(null);
        this.CompanyGsId.setValue(null);
        this.isPrivacyPolicyAccepted.setValue(null);
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            reenterEmail: this.reenterEmail,
            password: this.password,
            reenterPassword: this.reenterPassword,
            countryId: this.countryId,
            companyName: this.companyName,
            contactable: this.contactable,
            CompanyGsId: this.CompanyGsId,
            isPrivacyPolicyAccepted: this.isPrivacyPolicyAccepted
        };
    };
    ProfileModel.prototype.validateReenterPassword = function (c) {
        // if (!c.parent) return;
        // if (c.parent.controls['password'].value === c.value) {
        //     return null;
        // }
        // return {
        //     valid: false
        // };
        if (!c.parent)
            return;
        var password = c.parent.controls['password'];
        var reenterPassword = c.parent.controls['reenterPassword'];
        if (!password.value || !reenterPassword.value) {
            return null;
        }
        if (password.value !== reenterPassword.value) {
            reenterPassword.setErrors({
                "reenterPassword": false
            });
            return {
                "reenterPassword": false
            };
        }
        else {
            reenterPassword.setErrors(null);
        }
        return null;
    };
    ProfileModel.prototype.asyncCheckEmail = function (userService) {
        return function (c) {
            if (!c.parent)
                return;
            var email = c.parent.controls['email'];
            return new Promise(function (resolve, reject) {
                userService.checkEmail(email.value).then(function (response) {
                    if (response) {
                        resolve(null);
                    }
                    else {
                        resolve({ uniqueEmail: true });
                    }
                }, function () {
                    resolve({ uniqueEmail: true });
                });
            });
        };
    };
    ProfileModel.prototype.validateReenterEmail = function () {
        return function (group) {
            var email = group.controls['email'];
            var reenterEmail = group.controls['reenterEmail'];
            if (!email.value || !reenterEmail.value) {
                return null;
            }
            if (email.value !== reenterEmail.value) {
                reenterEmail.setErrors({
                    "reenterEmail": true
                });
            }
            else {
                reenterEmail.setErrors(null);
            }
            return null;
        };
    };
    ;
    ProfileModel.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileModel_Factory() { return new ProfileModel(i0.ɵɵinject(i1.UserService)); }, token: ProfileModel, providedIn: "root" });
    return ProfileModel;
}());
export { ProfileModel };
