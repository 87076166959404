import { Directive, EventEmitter, Output, HostListener } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Directive({
    selector: '[enterBtn]'
})
export class EnterBtnDirective {
    @Output() enterBtn: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private readonly loaderService: LoaderService
    ) { }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.loaderService.getLoader()) { return; }

        if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            this.enterBtn.next(event);
        }
    }
}
