import * as tslib_1 from "tslib";
import { AccountPage } from '@enums/accountPage.enum';
import { UserState } from '@enums/userState.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./loader.service";
import * as i4 from "./gsid-sso.service";
import * as i5 from "./configuration.service";
var UserService = /** @class */ (function () {
    function UserService(http, router, loaderService, gsidSsoService, configurationService) {
        this.http = http;
        this.router = router;
        this.loaderService = loaderService;
        this.gsidSsoService = gsidSsoService;
        this.configurationService = configurationService;
        var config = this.configurationService.getConfig();
        this.webshopUrl = config.AccountsUi;
        this.apiUrl = config.GsIdApi;
    }
    UserService.prototype.login = function (emailAddress, password) {
        var _this = this;
        var request = {
            'Email': emailAddress,
            'Password': password,
            'Token': null
        };
        this.loaderService.showLoader();
        return this.http.post((this.apiUrl + 'api/UserV2/LoginFromBCPromotionSite'), request)
            .toPromise()
            .then(function (response) {
            _this.loaderService.hideLoader();
            return _this.redirectByUserState(response);
        }, function (error) {
            _this.loaderService.hideLoader();
            throw error;
        });
    };
    UserService.prototype.forgotPassword = function (emailAddress) {
        var _this = this;
        var request = {
            'EmailAddress': emailAddress
        };
        this.loaderService.showLoader();
        return this.http.post(this.apiUrl + "api/UserV2/ResetUserPassword", request)
            .toPromise()
            .then(function (response) {
            _this.loaderService.hideLoader();
            return response;
        }, function (error) {
            _this.loaderService.hideLoader();
            throw error;
        });
    };
    UserService.prototype.userLoggedIn = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = Promise).resolve;
                        return [4 /*yield*/, this.gsidSsoService.getAccessToken()];
                    case 1: return [2 /*return*/, _b.apply(_a, [!!(_c.sent())])];
                }
            });
        });
    };
    UserService.prototype.logout = function (withoutRedirect) {
        this.gsidSsoService.logout();
        if (!withoutRedirect)
            this.router.navigate(['/login']);
    };
    UserService.prototype.signup = function (user) {
        var _this = this;
        this.loaderService.showLoader();
        return this.http.post(this.apiUrl + "api/UserV2/CreateAndVerifyUser", user)
            .toPromise()
            .then(function (response) {
            if (response.UserState !== UserState.ContactUKOfficeForNewInvitation) {
                _this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
            }
            else {
                _this.loaderService.hideLoader();
                return response;
            }
        }, function (error) {
            _this.loaderService.hideLoader();
            return {
                ErrorMessage: error,
                Success: false
            };
        });
    };
    UserService.prototype.checkEmail = function (email) {
        var request = {
            'EmailAddress': email,
        };
        return this.http.put((this.apiUrl + 'api/UserV2/CheckEmail'), request)
            .toPromise()
            .then(function (response) {
            return response;
        });
    };
    UserService.prototype.setNewPasswordToUser = function (newPasswordForm) {
        var _this = this;
        var request = {
            verificationCode: newPasswordForm.verificationCode,
            password: newPasswordForm.newPassword
        };
        this.loaderService.showLoader();
        return this.http.put((this.apiUrl + 'api/UserV2/SetNewPasswordToUser'), request)
            .toPromise()
            .then(function (response) {
            _this.loaderService.hideLoader();
            return response;
        }, function (error) {
            _this.loaderService.hideLoader();
            throw error;
        });
    };
    UserService.prototype.isVerificationCodeExists = function (code) {
        var _this = this;
        var request = {
            verificationCode: code,
        };
        this.loaderService.showLoader();
        return this.http.put((this.apiUrl + 'api/UserV2/IsVerificationCodeExists'), request)
            .toPromise()
            .then(function (response) {
            _this.loaderService.hideLoader();
            return response;
        });
    };
    UserService.prototype.redirectByUserState = function (response) {
        switch (response.UserState) {
            case UserState.UserAuthenticated:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break;
            case UserState.IdentityError:
                this.unhandledState(response.UserState);
                break;
            case UserState.UserAlreadyHasRightForBCAndAlreadyPurchasedTenant:
                this.redirectToWebshop(AccountPage.Subscriptions, response.AccessToken, response.TokenId);
                break; // Go to Subscriptions/Subscriptions page to purchase further BC SaaS licenses
            case UserState.UserAlreadyHasRightForBC:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break; // Go to Order/Pricing to purchase BC SaaS licenses
            case UserState.UserInvitationUpdatedWithBC:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break; // User already has invitation but not for BC. Token updated
            case UserState.UserInvitationUpdatedWithBCNotSuccess:
                this.redirectToWebshop(AccountPage.InitialPurchase, response.AccessToken, response.TokenId);
                break; // User already has invitation but not for BC. Token updated
            case UserState.ConnectedCompanyHasAnotherContractOwner:
            case UserState.ConnectedCompanyNotContainsExistingWebshopPartners:
            case UserState.ContactUKOfficeForNewInvitation:
                return response;
                break;
        }
    };
    UserService.prototype.unhandledState = function (userState) {
        alert("Unhandled user state: " + UserState[userState]);
    };
    UserService.prototype.redirectToWebshop = function (page, accessToken, sftoken) {
        switch (page) {
            case AccountPage.InitialPurchase:
                window.location.href = this.webshopUrl + "order/pricing?sftoken=" + sftoken + "&lang=en&accessToken=" + accessToken;
                break;
            case AccountPage.AdditionalPurchase:
                window.location.href = this.webshopUrl + "additional/pricing?sftoken=" + sftoken + "&lang=en&accessToken=" + accessToken;
                break;
            case AccountPage.Subscriptions:
                window.location.href = this.webshopUrl + "subscriptions/subscriptions?sftoken=" + sftoken + "&lang=en&accessToken=" + accessToken;
                break;
        }
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.GsidSsoService), i0.ɵɵinject(i5.ConfigurationService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
