import { Component, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';

@Component({
    selector: '[password-input]',
    templateUrl: 'passwordInput.html'
})
export class PasswordInputComponent implements AfterContentInit {
    @Input() label: string;
    @Input() type: string;
    @Input() model: any;
    @Input() email: any;
    @Input() submitted: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
    public passwordStrengthLevel = 0;
    public modelValue: any;
    public showError = false;

    constructor() { }

    ngAfterContentInit() {
        this.modelValue = this.model;
        this.checkPasswordStrengthLevel();
    }

    public checkPasswordStrengthHasNumber() {
        if (!this.modelValue) return false;
        const regexp = new RegExp('[0-9]', 'g');
        var numbers = this.modelValue.match(regexp);
        return numbers && numbers.length >= 2;
    }

    public checkPasswordStrengthHasLetter() {
        const regexp = new RegExp('[a-z]');
        return regexp.test(this.modelValue) && !!this.modelValue;
    }

    public checkPasswordStrengthHasCapital() {
        const regexp = new RegExp('[A-Z]');
        return regexp.test(this.modelValue);
    }

    public checkPasswordStrengthLength() {
        return !!this.modelValue && (this.modelValue.length >= 8);
    }

    public checkPasswordStrengthNotEqualEmail() {
        if (this.modelValue === undefined || this.email === undefined) return true;
        if (this.modelValue === this.email) return false;
        return true;
    }

    public checkPasswordStrengthHasSpecialCharacter() {
        const regexp = new RegExp('[^A-Za-z0-9]');
        return !regexp.test(this.modelValue);
    }

    public checkPasswordStrengthLevel() {
        this.passwordStrengthLevel = 0;
        if (this.checkPasswordStrengthHasNumber()) this.passwordStrengthLevel++;
        if (this.checkPasswordStrengthHasLetter()) this.passwordStrengthLevel++;
        if (this.checkPasswordStrengthHasCapital()) this.passwordStrengthLevel++;
        if (this.checkPasswordStrengthLength()) this.passwordStrengthLevel++;
        if (this.checkPasswordStrengthNotEqualEmail()) this.passwordStrengthLevel++;
        if (this.passwordStrengthLevel < 5) {
            this.modelChange.emit(null);
        } else {
            this.modelChange.emit(this.modelValue);
        }

    }

    inputOnBlur() {
        this.showError = false;
    }

    inputOnFocus() {
        this.showError = true;
    }

}
