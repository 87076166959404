<section class="vertical-center horizontal-center">
    <div class="login-form-wrapper forgot-password" *ngIf="!showSuccessMsg">
        <h1>{{'USER_FORGOT_SECTION_HEADER' | translate}}</h1>
        <div class="fix-content-wrapper">
            <div class="form-group label-inside" [formGroup]="forgotPasswordForm" [ngClass]="{'invalid': forgotPasswordForm.controls['email'].invalid && submitted}">
                <!-- <input type="text" [(ngModel)]="emailAddress" forgotPasswordForm /> -->
                <input type="text" [formControl]="forgotPasswordForm.controls['email']" name="email" data-hj-whitelist/>

                <label [ngClass]="{'label--empty': !forgotPasswordForm.controls['email'].value}">{{'USER_FORGOT_EMAIL_ADDRESS_LABEL' | translate}}</label>
                <div class="active dropdown-menu dropdown-notification dropdown-right password-strength__popup" *ngIf="showErrorMsg">
                    <div class="dropdown-notification__header">{{'USER_SIGN_UP_UNIQUE_EMAIL_NOTIFICATION_HEADER' | translate}}</div>
                    <div class="dropdown-notification__body">
                        <div>
                                {{'USER_FORGOT_ERROR_MESSAGE_INCORRECT_ADDRESS_MESSAGE' | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="left">
                    <a class="btn btn-link btn-block btn-back" (click)="onBackBtnClicked()" (escBtn)="onBackBtnClicked()">{{'USER_FORGOT_BACK_BUTTON' | translate}}</a>
                </div>
                <div class="right">
                    <a class="btn btn-primary btn-block" [ngClass]="{'disabled': resetInProgress === true}" (click)="resetBtnClicked()" (enterBtn)="resetBtnClicked()" >{{'USER_FORGOT_RESET_PASSWORD_BUTTON' | translate}}</a>
                </div>
            </div>
            
        </div>
    </div>



    <div *ngIf="showSuccessMsg" inline-email-notification [notificationHeader]="notificationHeader" [notificationMessage]="notificationMessage"
       (notificationLinkFunction)="logInNowBtnClicked()" [notificationLinkText]="notificationLinkText" [notificationHint]="notificationHint"
        [notificationClass]="notificationClass"></div>
</section>
