<div *ngIf="!signupFinished" class="signup-form-wrapper">
  <h1>{{'USER_SIGN_UP_SECTION_HEADER' | translate}}</h1>
  <form [formGroup]="signupForm">
    
    <a class="btn btn-primary btn-block" href="{{signUpUrl}}"
       data-e2e="signup__signup-btn">{{'USER_SIGN_UP_SIGN_UP_BUTTON' | translate}}</a>
  </form>



  <div class="signup__footer" *ngIf="!hideFooter">
    <span>{{'USER_SIGN_UP_FOOTER_HEADER' | translate}}</span>
    <br />
    <a [routerLink]="['/login']">{{'USER_SIGN_UP_FOOTER_LOG_IN_BUTTON' | translate}}</a>
  </div>
</div>
