import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private uiConfiguration: any;
  
  constructor(
    private http: HttpClient,
    private readonly loaderService: LoaderService
  ) { }

  getConfig() {
    return this.uiConfiguration;
  }

  initialize() {
    this.loaderService.showLoader();
      return this.http.get('Config').toPromise().then(response => {
      this.loaderService.hideLoader();
      this.uiConfiguration = response;
      return this.uiConfiguration;
    }, error => { console.log(error)});
  }

}
