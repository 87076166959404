<section class="section-login-signup">
    <div class="header">
        <div class="bimcloud-logo"></div>
        <h2 data-e2e="welcome-message">{{'USER_LOGIN_OR_SIGNUP_SECTION_HEADER' | translate}}</h2>
        <p class="note" [innerHTML]="'USER_LOGIN_OR_SIGNUP_NOTE' | translate"></p>
    </div>

    <div class="col" login [hideFooter]="true" [isLoginOrSignup]="true"></div>
    <div class="col" signup [hideFooter]="true" [autoLogin]="true"></div>
</section>
