<header>
  <div class="site-navigation__left">
    <span>
      <a class="left icon-hamburger" ng-click="hamburger.open = !hamburger.open"></a>
    </span>
    <a href="" title="GRAPHISOFT" class="graphisoft-logo left ir"></a>
    <a class="left">ID</a>
  </div>
  <div class="site-navigation__center">
  </div>

  <div class="site-navigation__right">

      <span class="dropdown profile-dropdown">
          <ng-container *ngIf="userLoggedIn">
              <avatar (click)="showProfileBtnClicked()"></avatar>
              <a (click)="showProfileBtnClicked()" class="user-name"></a>
              <ul class="dropdown-menu" [ngClass]="{'active': showProfileDropdown}">
                  <li>{{'HEADER_MENU_PROFILE_LABEL' | translate}}</li>
                  <li class="separated"><span (click)="logout()">{{'HEADER_MENU_SIGN_OUT_LINK_LABEL' | translate}}</span></li>
              </ul>
          </ng-container>
      </span>

      
    <span class="products-dropdown dropdown dropdown--no-arrow">
      <a title="Other GRAPHISOFT sites" class="icon-products" (click)="showProductsBtnClicked()"></a>
      <ul class="dropdown-menu" [ngClass]="{'active': showProductsDropdown}">
        <li>{{'HEADER_PRODUCTS_DROPDOWN_HEADER' | translate}}</li>
        <li><a href="http://myarchicad.com" target="_blank" title="myARCHICAD">myARCHICAD</a></li>
        <li><a href="http://helpcenter.graphisoft.com" target="_blank" title="Help Center">Help Center</a></li>
        <li><a href="http://archicad-talk.graphisoft.com/" target="_blank" title="ARCHICAD Talk">ARCHICAD Talk</a></li>
        <li><a href="http://bimcomponents.com" target="_blank" title="BIMcomponents">BIMcomponents</a></li>
        <li>
          <a href="http://bimx.graphisoft.com" target="_blank" title="BIMx Model Transfer">BIMx Model Transfer</a>
        </li>
        <li><a href="http://gdl.graphisoft.com" target="_blank" title="GDL center">GDL center</a></li>
      </ul>
      </span>
     
  </div>
</header>
