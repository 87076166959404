import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UserState } from '@enums/userState.enum';
var SignupComponent = /** @class */ (function () {
    function SignupComponent(fb, loaderService, route, router, translateService, profileModel, userService, configurationService) {
        this.loaderService = loaderService;
        this.router = router;
        this.translateService = translateService;
        this.profileModel = profileModel;
        this.userService = userService;
        this.configurationService = configurationService;
        this.signupFormSubmitted = false;
        this.signupFinished = false;
        this.notificationHeader = 'USER_SIGN_UP_SUCCESS_NOTIFICATION_HEADER';
        this.notificationMessage = 'USER_SIGN_UP_SUCCESS_NOTIFICATION_MESSAGE';
        this.notificationLinkText = 'USER_SIGN_UP_SUCCESS_NOTIFICATION_LOGIN_BUTTON';
        this.notificationLinkAddress = '/login';
        this.notificationClass = 'dark';
        this.isContactPrefInfoHidden = false;
        this.isPrivacyPolicyInfoHidden = false;
        this.showContactPartnerErrorMsg = false;
        this.signupForm = fb.group(this.profileModel.getForm(), { validator: this.profileModel.validateReenterEmail() });
    }
    SignupComponent.prototype.ngOnInit = function () {
        var config = this.configurationService.getConfig();
        this.signUpUrl = config.AccountsUi + "shop/signup";
    };
    SignupComponent.prototype.setPassword = function (e) {
        this.signupForm.controls['password'].setValue(e);
    };
    SignupComponent.prototype.setCountry = function (e) {
        this.signupForm.controls['countryId'].setValue(e.CountryId);
    };
    SignupComponent.prototype.signupBtnClicked = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showContactPartnerErrorMsg = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        //TODO: event-be jön a token, amit majd fel kell küldeni
                        if (this.loaderService.getLoader())
                            return [2 /*return*/];
                        this.signupFormSubmitted = true;
                        if (!this.signupForm.valid) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.userService.signup(this.signupForm.value)];
                    case 2:
                        response = _a.sent();
                        if (response.UserState === UserState.ContactUKOfficeForNewInvitation) {
                            this.showContactPartnerErrorMsg = true;
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SignupComponent.prototype.logInNowBtnClicked = function () {
        // if (this.loaderService.getLoader()) return;
        // this.userService.login(this.signupForm.controls['email'].value, this.signupForm.controls['password'].value).then((response) => {
        // }, (error: any) => {
        //   console.log(error);
        // });
    };
    SignupComponent.prototype.onPaste = function (e) {
        e.stopPropagation();
        e.preventDefault();
    };
    SignupComponent.prototype.onContactPrefInfoClicked = function () {
        this.isContactPrefInfoHidden = !this.isContactPrefInfoHidden;
    };
    SignupComponent.prototype.onPrivacyPolicyInfoClicked = function () {
        this.isPrivacyPolicyInfoHidden = !this.isPrivacyPolicyInfoHidden;
    };
    return SignupComponent;
}());
export { SignupComponent };
