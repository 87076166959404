import * as tslib_1 from "tslib";
import { AfterContentInit } from '@angular/core';
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(userService, gsidSsoService) {
        this.userService = userService;
        this.gsidSsoService = gsidSsoService;
        this.isAdministrator = false;
        this.isContractOwner = false;
    }
    AvatarComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AvatarComponent.prototype.ngAfterContentInit = function () {
        if (this.currentUser && this.currentUser.Company && this.currentUser.Company.Owner.GsId === this.user.GsId && !this.hideAdministrator) {
            this.isAdministrator = true;
        }
        this.hasContractOwnerRole();
    };
    AvatarComponent.prototype.hasContractOwnerRole = function () {
        return;
    };
    AvatarComponent.prototype.getFirstLetter = function (name) {
        if (!name)
            return '?';
        return name[0];
    };
    return AvatarComponent;
}());
export { AvatarComponent };
