<!-- <modal-placeholder></modal-placeholder> -->
<!-- <div class="banner-notification">
  <span>
    From Saturday, Aug 13, 8 AM (CEST) till Sunday, Aug 14, 8 PM (CEST), we will perform scheduled server
    maintenance. During this time, the Graphisoft Store will be unavailable. We appreciate your patience and
    understanding. - The Graphisoft Team
  </span>
</div> -->

<div class="content background-user">
  <app-nav-menu></app-nav-menu>

  <div class="container">
    <router-outlet></router-outlet>
  </div>
  <footer app-footer></footer>
  <div loader></div>
</div>
