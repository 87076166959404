import { Component } from '@angular/core';

@Component({
    selector: '[app-footer]',
    templateUrl: 'footer.html'
})
export class FooterComponent {
    public year: any;
    constructor() {
        this.year = new Date().getFullYear();        
    }
}
