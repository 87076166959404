import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
    }
    ConfigurationService.prototype.getConfig = function () {
        return this.uiConfiguration;
    };
    ConfigurationService.prototype.initialize = function () {
        var _this = this;
        this.loaderService.showLoader();
        return this.http.get('Config').toPromise().then(function (response) {
            _this.loaderService.hideLoader();
            _this.uiConfiguration = response;
            return _this.uiConfiguration;
        }, function (error) { console.log(error); });
    };
    ConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
