import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  templateUrl: 'newPassword.html',
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  private routeSub: any;
  private verificationCodeChanged: Subject<any> = new Subject<string>();
  private verificationCodeChangedSubscription: any;

  public emailAddress: string = '';
  public resetInProgress = false;
  public showErrorMsg = false;
  public submitted: boolean = false;

  public notificationHeader: any = 'USER_NEW_PASSWORD_SUCCESS_NOTIFICATION_HEADER';
  public notificationMessage: any = 'USER_NEW_PASSWORD_SUCCESS_NOTIFICATION_MESSAGE';
  public notificationLinkText: any = 'USER_NEW_PASSWORD_SUCCESS_NOTIFICATION_LOGIN_BUTTON';
  public notificationHint:any;
  public notificationLinkAddress: any = '/login';
  public notificationClass: any = 'dark';

  public newPasswordForm: any;

  public invalidVerificationCode: boolean = false;
  public showInvalidVerificationCode: boolean = false;
  public verificationCodeCheckInProgress: boolean = false;
  public showSuccessMsg: boolean = false;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) {
    this.resetNewPasswordForm();
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      setTimeout(() => {
        if (params['verificationCode']) {
          this.newPasswordForm.verificationCode = params['verificationCode'];
          this.checkVerificationCode(this.newPasswordForm.verificationCode);
        }
      }, 0);
    });

    this.verificationCodeChangedSubscription = this.verificationCodeChanged
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .subscribe((a: any) => {
        this.checkVerificationCode(a);
      });
  }

  checkVerificationCode(value: any) {
    this.verificationCodeCheckInProgress = true;
    this.userService.isVerificationCodeExists(value).then((response: any) => {
      this.verificationCodeCheckInProgress = false;
      this.invalidVerificationCode = !response;
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.verificationCodeChangedSubscription.unsubscribe();
  }

  public verificationCodeOnChange() {
    this.verificationCodeChanged.next(this.newPasswordForm.verificationCode);
  }

  private resetNewPasswordForm() {
    this.newPasswordForm = {
      verificationCode: null,
      newPassword: null,
      reenterPassword: null
    };
  }


  public checkReenterPassword() {
    if (!this.newPasswordForm.reenterPassword || !this.newPasswordForm.newPassword) return null;
    if (this.newPasswordForm.newPassword === this.newPasswordForm.reenterPassword) return true;
    return false;
  }

  inputOnBlur() {
    this.showInvalidVerificationCode = false;
  }

  inputOnFocus() {
    this.showInvalidVerificationCode = true;
  }

  onVerificationCodeChanged(e: any) {
    this.invalidVerificationCode = false;
    if (this.newPasswordForm.verificationCode.length === 36) {
      this.verificationCodeChanged.next(this.newPasswordForm.verificationCode);
    }
  }

  saveNewPassword() {
    this.submitted = true;
    if (!this.newPasswordForm.verificationCode || this.verificationCodeCheckInProgress || this.invalidVerificationCode || !this.checkReenterPassword()) return;

    this.userService.setNewPasswordToUser(this.newPasswordForm).then((response: any) => {
      this.showSuccessMsg = true;
    }, (error: any) => {

    });
  }

  logInNowBtnClicked() {
    this.router.navigate(['/login']);
  }
}
